
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner-inner {
    width: 100%;
    max-width: 1280px;
    padding-right: 0;
    padding-left: 0;
    @media screen and (min-width: 1024px) {
        padding-right: 26px;
        padding-left: 26px;
    }
}
.image {
    width: 100%;
    max-width: 50%;
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
    }
}
.main-banner {
    width: 100vw;
    min-height: calc(100vh - 58px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 30px 20px;
    background-image: url("/assets/images/mobile-bg.jpg");
    background-color: #e4f2ff;
    background-size: cover;
    background-repeat: no-repeat;
    @include md {
        background-size: contain;
        background-position: bottom;
        background-repeat: repeat-x;
        background-image: url("/assets/images/background.jpg");
    }

    .form {
        z-index: 5;
        padding: 30px 24px;
        width: 100%;
        max-width: 630px;
        border-radius: 43px;
        background-color: white;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        // margin-top: 6vh;/
        @media screen and (min-width: 1024px) {
            width: 630px;
        }
        @include md {
            padding: 30px 50px;
        }

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include md {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 24px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            @include md {
                font-size: 28px !important;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-weight: 700 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            font-size: 24px !important;
            @include md {
                margin-left: -50px;
                margin-right: -50px;
                font-size: 28px !important;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include md {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 999px !important;
            background-color: #3a6ab4;
            color: #fff;
            transition: all 0.2s ease-in-out;
            svg {
                path {
                    fill: #fff;
                }
            }
            @include md {
                max-width: 416px !important;
                font-size: 1.125rem;
                line-height: 1.75rem;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include md {
                max-width: 416px;
            }
            > input,
            select {
                border-radius: 999px !important;
            }
        }
        .step-block:nth-child(1) {
            min-height: 460px;
            .fields-wrapper {
                margin-top: 0;
            }
            .form-field > label {
                @apply text-base mb-4 font-bold;
                text-align: center;
                @include md {
                    @apply text-lg;
                }
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        border: 2px solid black;
                        filter: brightness(1.1);
                    }
                }
                label {
                    background: linear-gradient(
                        180deg,
                        #ffd79a 0%,
                        #fcbe60 100%
                    );
                    border-radius: 10px !important;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    flex-direction: row;
                    min-height: 50px;
                    font-weight: 700;
                    width: 100%;
                    color: #000;
                    justify-content: center;

                    & > span {
                        margin-top: 0;
                    }
                    & > div {
                        margin-right: 20px;
                        margin-left: 20px;
                        img {
                            width: 100%;
                            // filter: brightness(0) invert(1);
                        }
                    }
                    &:hover {
                        filter: brightness(1.1);
                    }
                    @include md {
                        > span {
                            @apply text-lg;
                        }
                        min-height: 62px;
                    }
                    @apply text-center;
                }
                @include md {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include md {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        .zipcode-expandable {
            > div:first-child {
                border-radius: 5px;
                @include sm {
                    border-radius: 999px;
                }
                overflow: hidden;
                border-color: #3a6ab4;
                color: white;
                > div:first-child {
                    border-color: #3a6ab4;
                    background-color: #3a6ab4 !important;
                }
            }
            > span {
                background-color: white;
                color: #a09f9f;
            }
            > div:nth-child(2),
            div:nth-child(3) {
                @include sm {
                    width: calc(100% - 46px);
                    left: 23px;
                    top: 52px;
                }
                z-index: 1000;
            }
        }
    }
}

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.back-button {
    left: 40px;
    @media screen and (max-width: 1023px) {
        left: 25px;
    }
}
